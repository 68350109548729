import axios from "axios";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import WarningIcon from '@mui/icons-material/Warning';
import Stack from '@mui/material/Stack';
import Actions from "../Actions";
import { connect } from 'react-redux';
import React, { useEffect, useState } from "react";
import { DatePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Body from "./Body";
import {
    Checkbox,
    FormControlLabel,
    TextField,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Slider
} from "@material-ui/core";
import AgentsLimitationsTable from "./AgentsLimitationsTable";
import LeadsByDateTable from "./LeadsByDate";
import {Link} from "react-router-dom";

const EXPOSED_SETTINGS = [
    {
        type: "text",
        label: "General settings",
        width: '100%'
    },
    {
        var:'HEADROOM_TARGET',
        label: 'Headroom Target',
        unsetValue: -1,
        field: TextField,
    },
    {
        var:'LEAD_FRESHNESS_SECONDS',
        label: 'Lead Freshness(Seconds)',
        unsetValue: 600,
        field: TextField,
    },
    {
        var:'WARM_PULL_COUNT_ADJ_RATE',
        label: 'Warm Calls Per Headroom',
        unsetValue: -1,
        field: Slider,
    },
    {
        var:'RAW_PULL_COUNT_ADJ_RATE',
        label: 'Raw Calls Per Headroom',
        unsetValue: -1,
        field: Slider,
    },
    {
        var:'IMMEDIATE_DIALING_IGNORE_COVERAGE',
        label: 'Ignore Buyer Availability For Fresh Leads',
        unsetValue: false,
        field: Checkbox,
    },
    {
        var:'DIAL_MULTI_TRANSFERS',
        label: 'Dial Multi Transfers',
        unsetValue: true,
        field: Checkbox,
    },
    {
        var:'MULTI_TRANSFER_MAX_DURATION',
        label: 'MT max duration (seconds)',
        unsetValue: -1,
        field: TextField,
    },
    {
        var:'MIN_PHONE_SCORE',
        label: 'Minimum Phone Score',
        unsetValue: 10,
        field: TextField,
    },
    {
        var:'MAX_CALLS_ATTEMPTS',
        label: 'Max call attempts',
        unsetValue: null,
        field: TextField,
    },
    {
        type: "link",
        label: "Predictive algorithm",
        width: '100%',
        link: "/predictions",
    },
    {
        var:'PREDICTIVE_ALGO_ACTIVE',
        label: 'Enabled',
        unsetValue: true,
        field: Checkbox,
        width: "100%"
    },
    {
        var: 'PREDICTION_PERIOD_IN_MINUTES',
        label: 'Prediction Period (Minutes)',
        unsetValue: null,
        field: TextField,

    },
    {
        var: 'RAW_CALL_COUNT_PERIOD_IN_MINUTES',
        label: 'Raw Call Count Period (Minutes)',
        unsetValue: null,
        field: TextField,

    },
    {
        var: 'VELOCITY_MAX',
        label: 'Velocity Max',
        unsetValue: null,
        field: TextField,

    },
    {
        var: 'AGENT_VELOCITY_PERIOD_IN_DAYS',
        label: 'Agent Velocity Period (Days)',
        unsetValue: null,
        field: TextField,

    },
    {
        var: 'AGENTS_STACK_SIZE',
        label: 'Agents Stack Size',
        unsetValue: null,
        field: TextField,

    },
    {
        var: 'AGENTS_MAX',
        label: 'Agents Max',
        unsetValue: null,
        field: TextField,
    },
    {
        var: 'PREDICTION_ACCURACY_RATE_FOR_RAW_CALLS',
        label: 'Accuracy factor, when headroom = 0',
        unsetValue: 1,
        field: TextField
    },
    {
        var: 'PREDICTION_ACCURACY_RATE_FOR_RAW_CALLS_WHEN_HEADROOM_NOT_ZERO',
        label: 'Accuracy factor, when headroom > 0',
        unsetValue: 1,
        field: TextField
    },
    {
        type: "slider",
        var:'PREDICTION_ACCELERATION',
        label: 'Acceleration',
        unsetValue: 0,
        min: -100,
        minLabel: '-100%',
        max: 100,
        maxLabel: '+100%',
        step: 1
    },
    {
        var: 'AGENTS_MIN',
        label: 'Agents Limitations',
        unsetValue: null,
        width: '100%',
        field: AgentsLimitationsTable,
    },
    {
        type: "text",
        label: "Auto Flex",
        width: '100%'
    },
    {
        var:'FLEX_AUTOMATION',
        label: 'Enabled',
        unsetValue: false,
        field: Checkbox,
        width: "100%"
    },
    {
        var:'COMPANY_FLEX_MAX_QUEUE',
        label: 'Company Flex Max Queue seconds',
        unsetValue: 10,
        field: TextField,
    },
    {
        var:'COMPANY_FLEX_CHECK_INTERVAL_SECONDS',
        label: 'Company Flex Check Interval Seconds',
        unsetValue: 10,
        field: TextField,
    },
    {
        var:'COMPANY_CALLS_FILTER',
        label: 'Minimal duration of the companies calls',
        unsetValue: 70,
        field: TextField,
    },
    {
        var:'FLEX_SIZE',
        label: 'Flex size',
        unsetValue: 1,
        field: TextField,
    },
    {
        type: "link",
        label: "Agents Ranking System",
        link: "/agent/rank",
        width: '100%'
    },
    {
        var:'AGENTS_RANKING_SYSTEM_ENABLED',
        label: 'Enabled',
        unsetValue: true,
        field: Checkbox,
        width: "100%"
    },
    {
        type: "text",
        label: "Auto Headroom Target",
        width: '100%'
    },
    {
        var:'AUTO_HEADROOM_TARGET_ENABLED',
        label: 'Enabled',
        unsetValue: false,
        field: Checkbox,
        width: "100%"
    },
    {
        var:'AUTO_HEADROOM_TARGET_THRESHOLD',
        label: 'Threshold for long distance R1 and R2 leads',
        unsetValue: 500,
        field: TextField,
    },
    {
        type: "link",
        label: "Press one",
        width: '100%',
        link: "/press-one",
    },
    {
        var:'PRESS_ONE_LONG_DISTANCE',
        label: 'Long distance agents',
        unsetValue: false,
        field: Checkbox,
        width: "50%"
    },
    {
        var:'PRESS_ONE_LOCAL',
        label: 'Local agents',
        unsetValue: false,
        field: Checkbox,
        width: "50%"
    },
    {
        var:'PRESS_ONE_CALL_INTERVAL',
        label: 'Call interval (sec)',
        unsetValue: null,
        field: TextField,
    },
    {
        var:'PRESS_ONE_THRESHOLD_PHONE_SCORE',
        label: 'Threshold for Phone score in %',
        unsetValue: null,
        field: TextField,
    },
]

const CALL_TIMEOUTS = {};
const CALL_TIMEOUTS_MAX_MATCH_COUNT = 4;
const CALL_TIMEOUTS_MAX_CALL_COUNT = 12;


const Settings = (props) => {

  const { dispatch } = props;
  const [ callTimeouts, setCallTimeouts ] = useState(CALL_TIMEOUTS);
  const [ callTimeoutsTouched, setCallTimeoutsTouched ] = useState(false);
  const [ startDate, setStartDate] = useState(null);
  const [ endDate, setEndDate] = useState(null);
  const [ leadsByDate, setLeadsByDate ] = useState([]);
  const [ leadsDisabled, setLeadsDisabled ] = useState({ by_date: {}, by_count: {}, by_match_count: {} });
  const [ leadsCount, setLeadsCounts ] = useState([]);
  const [ calendarOpen, setCalendarOpen ] = useState(false);
  const settingsToWatch = [];
  const [ rawPcar, setRawPcar ] = useState(0);
  const [ warmPcar, setWarmPcar ] = useState(0);

  for (let setting of EXPOSED_SETTINGS){
    // eslint-disable-next-line
    const [get, set] = useState(setting.unsetValue)
    setting.get = get
    setting.set = set
    settingsToWatch.push(get)
  }

  const [isUpdating, setIsUpdating] = useState(false)
  const [queueSize, setQueueSize] = useState("loading...")


  const fetchData = async () => {
    setIsUpdating(true);

    let settingsResponse = await axios.get("/buffer/status/settings");

    dispatch(Actions.setSettings({ ...settingsResponse.data }));

    EXPOSED_SETTINGS.forEach((setting) => {
        const val = settingsResponse.data[setting.var];
        setting.set(["true", "false"].includes(val) ? !!val : val);
    });

    if (settingsResponse.data.callTimeouts) {
        const newTimeouts = createCallTimeoutsMatrix();
        const timeouts = settingsResponse.data.callTimeouts;

        Object.keys(timeouts).forEach( (matchCount) => {
            const row = timeouts[matchCount];

            Object.keys(row).forEach( (callCount) => {
                const cell = row[callCount];

                if (Number(callCount) === 0) return;
                if (! cell) return;
                if (typeof cell.enabled === 'undefined') return;
                if (typeof cell.timeout === 'undefined') return;

                newTimeouts[matchCount][callCount] = cell;
            })
        });


        setCallTimeouts(newTimeouts);
        dispatch(Actions.setCallTimeouts(newTimeouts));
    }

    if (settingsResponse?.data?.leads_disabled) {
        if (settingsResponse.data.leads_disabled?.by_date) {
            updateLeadsDisabledByDate(settingsResponse.data.leads_disabled?.by_date);
        }
    }

    if (settingsResponse?.data?.RAW_PULL_COUNT_ADJ_RATE) {
        setRawPcar(settingsResponse?.data?.RAW_PULL_COUNT_ADJ_RATE === 0 ? 0 : Math.round(1 / settingsResponse?.data?.RAW_PULL_COUNT_ADJ_RATE))
    }

    if (settingsResponse?.data?.WARM_PULL_COUNT_ADJ_RATE) {
        setWarmPcar(settingsResponse?.data?.WARM_PULL_COUNT_ADJ_RATE === 0 ? 0 :  Math.round(1 / settingsResponse?.data?.WARM_PULL_COUNT_ADJ_RATE))
    }

    setCallTimeoutsTouched(false);
    setIsUpdating(false)
    fetchQueueStats()
  }

  const fetchQueueStats = async ()=>{
    let queueResponse = await axios.get("/buffer/status/transfer-queue");
    setQueueSize(queueResponse.data.size)
    setTimeout(fetchQueueStats, 2000)
  }

  const createCallTimeoutsMatrix = () => {
      let timeouts = {};
      for(let row = 0; row < CALL_TIMEOUTS_MAX_MATCH_COUNT; row++) {
          timeouts[row] = {};
          for (let column = 1; column <= CALL_TIMEOUTS_MAX_CALL_COUNT; column++) {
              timeouts[row][column] = {
                enabled: true,
                timeout: 180
              };
          }
      }
      setCallTimeouts(timeouts);

      return timeouts;
  }

  const handleCallTimeoutManualInput = (event, row, column) => {
    event.preventDefault();

    let timeout = parseInt(event.target.value);

    if (Number.isNaN(timeout)) {
      timeout = 0;
    }

    if (timeout < 0) {
      timeout = 0;
    }

    if (timeout > 9999) {
      timeout = parseInt(event.target.value.slice(0, 4));
    }

    const updatedCounts = {
        ...callTimeouts,
        [row]: {
          ...callTimeouts[row],
          [column]: {
            ...callTimeouts[row][column],
            timeout: timeout,
          },
        },
      };

      setCallTimeouts(updatedCounts);
      setCallTimeoutsTouched(true);
  }



    const handleLeadsByDateCheck = (event, date) => {
        const disabled = event.target.checked;

        setLeadsDisabled(prevState => {
          const newState = { ...prevState, by_date: { ...prevState.by_date } };

          const by_date = newState.by_date;
          if (!disabled) {
            by_date[date] = true;
          } else {
            delete by_date[date];
          }

          return newState;
        });

        setLeadsDisabled(prevState => ({ ...prevState, update: true }));
      };


  useEffect(() => {
      if (! leadsDisabled.update ) return;

      const data = JSON.parse(JSON.stringify(leadsDisabled));
      delete data.update;

      sendLeadsDisabledSettings(data);
  }, [ leadsDisabled ]);


  useEffect(() => {
    const fetchLeadsByDate = async () => {
      try {
        if (startDate && endDate) {
          const filteredLeads = await axios.get(`/buffer/statistic/lead-counts/by-created-date?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
          setLeadsByDate(filteredLeads?.data);
        } else {
          setLeadsByDate([]);
        }
      } catch (error) {
        console.log(error);
        setLeadsByDate([]);
      }
    };
    fetchLeadsByDate();
  }, [ startDate, endDate ]);



  const sendLeadsDisabledSettings = async data => {
    await axios.post("/buffer/update/settings",
        {
            leads_disabled: {
                by_date: data?.by_date
            }
        });
  }


  const updateLeadsDisabledByDate = (newLeadsByDate) => {
    setLeadsDisabled(prevState => ({
        ...prevState,
        by_date: newLeadsByDate
    }));
  }

  const fetchLeads = async ()=>{
    try {
        const result = await axios.get(`/buffer/statistic/lead-counts/by-call-count?startDate=${moment().format("YYYY-MM-DD")}&endDate=${moment().format("YYYY-MM-DD")}`);
        setLeadsCounts(result.data);
    } catch (error) {
        console.log(error)
        setLeadsCounts([]);
    }
  }

  const handleEndDate = async (endDate) => {
    setEndDate(moment(endDate).format('YYYY-MM-DD'));
    setCalendarOpen(false);
  }

  const isCallCountDisabled = callCount => {
      for (const matchCount of Object.keys(callTimeouts)) {
          const row = callTimeouts[matchCount];
          if (! row[callCount].enabled) return false;
      }

      return true;
  }

    const isMatchCountDisabled = matchCount => {
        for (const callCount of Object.keys(callTimeouts[matchCount])) {
            const cell = callTimeouts[matchCount][callCount];
            if (! cell.enabled) return false;
        }

        return true;
    }

    const toggleCallCount = async (e, callCount) => {
        const status = e.target.checked;
        const timeouts = JSON.parse(JSON.stringify(callTimeouts));

        for (const matchCount of Object.keys(timeouts)) {
            const row = timeouts[matchCount];
            row[callCount].enabled = status;
        }

        setCallTimeouts(timeouts);
        setCallTimeoutsTouched(true);
    }

    const toggleMatchCount = async (e, matchCount) => {
        const status = e.target.checked;
        const timeouts = JSON.parse(JSON.stringify(callTimeouts));

        for (const callCount of Object.keys(timeouts[matchCount])) {
            const cell = timeouts[matchCount][callCount];
            cell.enabled = status;
        }

        setCallTimeouts(timeouts);
        setCallTimeoutsTouched(true);
    }

  useEffect(() => {
      createCallTimeoutsMatrix();
      fetchLeads();
      fetchData();
  }, []);

  const getChangedSettings = () =>  EXPOSED_SETTINGS.filter((setting) => setting.touched);

  const isSavingDisabled = () => {
      const hasChanged = getChangedSettings().length > 0;

      return ! hasChanged && ! callTimeoutsTouched;
  }

  const saveSettings = async () => {
        let hasChanged = false
        const changedSettings = getChangedSettings()
            .reduce((changedSettings, setting) => {
                hasChanged = true
                changedSettings[setting.var] = setting.get
                return changedSettings
            }, {})

        if (isSavingDisabled()) {
            toast.warn('No changes');
            return;
        }

        dispatch(Actions.setSettings(changedSettings))

        if (callTimeoutsTouched) {
            changedSettings.callTimeouts = callTimeouts;
        }

        await axios
            .post("/buffer/update/settings", changedSettings)
            .then(async () => {
                await fetchData();
                toast.success("Settings have been updated")
            })
            .catch(error => {
                const message = typeof error.response.data === 'string' ? error.response.data : error.response.data.message;
                toast.warn(message);
            });
  }

    const handleSlider = (target,  setting) => {
        if (target.value <= 1) {
            setting.set(0)
            if (setting.var === 'WARM_PULL_COUNT_ADJ_RATE') {
                setWarmPcar(0);
            } else {
                setRawPcar(0);
            }
        } else {
            if (setting.var === 'WARM_PULL_COUNT_ADJ_RATE') {
                setWarmPcar(parseInt(target.value))
            } else setRawPcar(parseInt(target.value));

            setting.set(1 / parseInt(target.value));
        }

        setting.touched = true;
    }

    const handleSliderField = (value, setting) => {
        value = Number(value);

        if (isNaN(value)) return;
        if (value < setting.min) return;
        if (value > setting.max) return;

        setting.set(value);
        setting.touched = true;
    }

  const handleCallRate = (target, setting) => {
    const value = parseInt(target.value);
    if (isNaN(value) || value < 0 || value > 150) {
        setting.set(0);
        if (setting.var === 'WARM_PULL_COUNT_ADJ_RATE') {
            setWarmPcar(warmPcar);
        } else {
            setRawPcar(rawPcar);
        }
    } else {
        if (setting.var === 'WARM_PULL_COUNT_ADJ_RATE') {
            setWarmPcar(value);
        } else {
            setRawPcar(value);
        }
        setting.set(value === 0 ? 0 : 1 / value);
    }
    setting.touched = true;
}

const handleSingleCheckbox = (e, rowIndex, columnIndex) => {
    setCallTimeouts(prevState => {
        const updatedCounts = { ...prevState };

        updatedCounts[rowIndex][columnIndex] = {
        ...updatedCounts[rowIndex][columnIndex],
        enabled: !updatedCounts[rowIndex][columnIndex]['enabled']
        };

        return updatedCounts;
    });


    setCallTimeoutsTouched(true);
};

  const showSetting = setting => {
      const Field = setting.field

      if (setting.var === 'WARM_PULL_COUNT_ADJ_RATE' || setting.var === 'RAW_PULL_COUNT_ADJ_RATE') {
          return (
              <FormControlLabel
                  label={setting.label}
                  labelPlacement="top"
                  style={{marginBottom: 15}}
                  className="container"
                  control={
                      <>

                          <input
                              onChange={({ target }) => handleSlider(target, setting)}
                              id={setting.var}
                              type="range"
                              value = {setting.var === 'WARM_PULL_COUNT_ADJ_RATE' ? warmPcar : rawPcar}
                              min={0}
                              step={1}
                              max={150}
                              aria-labelledby="non-linear-slider"
                          />

                          <TextField
                              onChange={({ target }) => handleCallRate(target, setting)}
                              id={setting.var}
                              type="text"
                              value = {setting.var === 'WARM_PULL_COUNT_ADJ_RATE' ? warmPcar : rawPcar}
                              inputMode='numeric'
                              style={{ textAlign: 'center' }}

                          />
                      </>
                  }
              />
          )
      }
      else if (setting.var === 'IMMEDIATE_DIALING_IGNORE_COVERAGE' || setting.var === 'DIAL_MULTI_TRANSFERS') {
          return (
              <FormControlLabel
                  label={setting.label}
                  labelPlacement="top"
                  style={{marginBottom: 35}}
                  control={
                      <Field
                          onChange={({ target }) => {
                              if(target.type == "checkbox"){
                                  setting.set(!!target.checked)
                              }else{
                                  setting.set(target.value)
                              }
                              setting.touched = true
                          }}
                          id={setting.var}
                          value={setting.get}
                          checked={setting.get}
                          style={{color: '#07b839'}}
                      />
                  }
              />
          )
      }
      else if (setting.type === "text") {
          return <div style={{ width: '100%', textAlign: 'center' }}>
              <hr />
              <h5>{ setting.label }</h5>
              <hr />
          </div>
      } else if (setting.type === "link") {
          return <Link style={{ width: '100%', textAlign: 'center' }} to={{ pathname: setting.link }}>
              <hr/>
              <h5>{ setting.label }</h5>
              <hr/>
          </Link>
      }
      else if (setting.type === "slider") {
          return (
              <FormControlLabel
                  label={setting.label}
                  labelPlacement="top"
                  style={{marginBottom: 15}}
                  className="container"
                  control={
                      <>
                          <div style={{display: 'flex', marginTop: '10px' }}>
                              <div style={{ fontSize: '14px', marginRight: '5px'}}>{setting.minLabel}</div>
                              <input
                                  onChange={({target}) => handleSliderField(target.value, setting)}
                                  id={setting.var}
                                  type="range"
                                  value={setting.get}
                                  min={setting.min}
                                  step={setting.step}
                                  max={setting.max}
                                  aria-labelledby="non-linear-slider"
                              />
                              <div style={{ fontSize: '14px', marginLeft: '5px'}}>{setting.maxLabel}</div>
                          </div>

                          <TextField
                              onChange={({target}) => handleSliderField(target.value, setting)}
                              id={setting.var}
                              type="text"
                              value={setting.get}
                              inputMode='numeric'
                              style={{textAlign: 'center'}}
                          />
                      </>
                  }
              />
          );
      } else {
          return (
              <FormControlLabel
                  label={setting.label}
                  labelPlacement="top"
                  style={{marginBottom: 35 }}
                  control={
                      <Field style={{color: '#07b839'}}
                             onChange={({ target }) => {
                                 const status = !!target.checked;
                                 if(target.type == "checkbox"){
                                     setting.set(!!target.checked)
                                 }else{
                                     setting.set(target.value)
                                 }

                                 if (setting.var === "AGENTS_RANKING_SYSTEM_ENABLED" && status) {
                                     const paStatus = EXPOSED_SETTINGS.find( item => item.var === "PREDICTIVE_ALGO_ACTIVE");
                                     paStatus.set(false);
                                 }

                                 if (setting.var === "PREDICTIVE_ALGO_ACTIVE" && status) {
                                     const arsStatus = EXPOSED_SETTINGS.find( item => item.var === "AGENTS_RANKING_SYSTEM_ENABLED");
                                     arsStatus.set(false);
                                 }

                                 setting.touched = true
                             }}
                             id={setting.var}
                             value={setting.get}
                             checked={setting.get}
                             disabled={setting.disabled}
                      />
                  }
              />
          )
      }
  }

  return(
    <Body isLoading={isUpdating}>
        <div style={{ maxWidth: '900px', paddingTop:'2rem', fontSize:'1.5rem', display:'flex', justifyContent:'space-between', margin: 'auto'}} >
            <div>
                <h2>SETTINGS</h2>
                <div style={{ fontSize: '1rem' }}>Queue Size: {queueSize}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button className="save-button"
                        disabled={isSavingDisabled()}
                        onClick={saveSettings}
                >Save</button>
            </div>
        </div>
        <div style={{ maxWidth: '900px', fontSize:'1.5rem', display:'flex', justifyContent:'center', margin: 'auto', flexWrap: 'wrap' }}>
            { EXPOSED_SETTINGS.map((setting, index) => (
                <div style={{ flexBasis: setting.width || "50%", display:'flex', justifyContent:'center' }}>
                    { showSetting(setting) }
                </div>
            )) }
        </div>
        <div className="previous_dates-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center'}}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    autoOk={true}
                    label="Choose a start date"
                    value={startDate}
                    onChange={(date) => setStartDate(moment(date).format('YYYY-MM-DD'))}
                    maxDate={new Date()}
                    onOpen={() => setCalendarOpen(true)}
                    onClose={() => setCalendarOpen(false)}
                />
                <DatePicker
                    autoOk={true}
                    label="Choose an end date"
                    value={endDate}
                    onChange={handleEndDate}
                    maxDate={new Date()}
                    onOpen={() => setCalendarOpen(true)}
                    onClose={() => setCalendarOpen(false)}
                />

            </MuiPickersUtilsProvider>
        </div>
        {<LeadsByDateTable data = {leadsByDate} startDate = { startDate } endDate = { endDate } calendarOpen = { calendarOpen } leadsDisabled = { leadsDisabled } handleLeadsByDateCheck = { handleLeadsByDateCheck }/>}
        <div className="call-timeouts-table" style={{ maxWidth: '80%', padding:'2rem', fontSize:'1.5rem', display:'flex', justifyContent:'center', margin: 'auto'}} >
            {Object.keys(callTimeouts).length && <Table>
                <TableHead>
                    <TableRow>
                        <td colSpan={13}>
                            <h2 className="th-row-header">Call Timeouts</h2>
                        </td>
                    </TableRow>
                    <TableRow>
                        <td colSpan={13}>
                            <Stack sx={{ width: '100%'}} spacing={0}>
                                <Alert severity="warning" style={{ width: '100%'}} variant='standard' icon={<WarningIcon fontSize='inherit' style={{ marginTop: '10px'}}/>}>
                                    <AlertTitle><h5>Warning</h5></AlertTitle>
                                    <p style={{fontSize: '16px'}}>Changing call timeout settings will result in the buffer pool refreshing and prevent the agents from dialing for several minutes</p>
                                </Alert>
                            </Stack>
                        </td>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={{width: '100px'}}>
                            <div className="match-call-counts-header">
                                <div className="sold-count-header">Call Count</div>
                                <hr/>
                                <div className="match-count-header">Match Count</div>
                            </div>
                        </TableCell>
                        {Object.keys(callTimeouts[0]).map( (columnIndex) => (
                            <TableCell key={columnIndex}>
                                <div className="rotation-wrapper">R : {columnIndex}</div>
                                <Checkbox onChange={(e) => toggleCallCount(e, columnIndex)}
                                          checked={isCallCountDisabled(columnIndex) }
                                          style={{marginLeft: '35px', color: '#07b839'}}
                                />
                            </TableCell>
                        ))}
                    </TableRow>


                    {Object.entries(callTimeouts).map(([rowIndex, row]) => (

                        <TableRow key={rowIndex}>
                            <TableCell>
                            {rowIndex}

                            <Checkbox
                                onChange={(e) => toggleMatchCount(e, rowIndex)}
                                checked={isMatchCountDisabled(rowIndex)}
                                style={{ marginLeft: '35px', color: '#01b839', width: '50px' }}
                            />
                            </TableCell>
                            {Object.keys(row).map((columnIndex) => (
                                <TableCell key={columnIndex}>

                                    <Checkbox style={{ color: '#07b839', position: 'relative', left: '35px'}} checked={callTimeouts[rowIndex][columnIndex]['enabled']} onChange={(event) => handleSingleCheckbox(event, rowIndex, columnIndex)}/>

                                    {Number(columnIndex) !== Object.entries(row).length && (
                                        <TextField
                                            type="number"
                                            value={callTimeouts[rowIndex][columnIndex]['timeout']}
                                            onChange={(event) => handleCallTimeoutManualInput(event, rowIndex, columnIndex)}
                                            style={{ width: '25px', position: 'relative', left: '55px', top: '10px' }}
                                        />
                                    )}
                                </TableCell>
                                ))}
                        </TableRow>
                        ))}
                </TableBody>
            </Table>}
        </div>

        <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    </Body>
  )
}

const mapStateToProps = (state) => {
    return EXPOSED_SETTINGS.reduce((accState, setting)=>{
        accState[setting] = state.settings[setting]
        return accState
    })
}

export default connect(mapStateToProps)(Settings);
